export const colorPallet = {
  primary: '#004bad',
  secondary: '#7c7099',
  accent: '#fb8500',
  background: '#18211f',
  popupBackground: '#716379',
  content1: '#23152f',
  content2: '#379683',
  text: '#ffffff',
  h1: '#ffffff',
}
//accent = orange
//primary = light blue
//secondary = dark blue
//background = darkgrey
//foreground = light grey
